import { memo, useMemo } from 'react';
import { Card, Tooltip, Typography } from '@material-ui/core';
import Chart from 'react-google-charts';
import { InfoTwoTone } from '@material-ui/icons';
import HelpTooltip from '../HelpTooltip';
import Toolbar from '../Toolbar';
import useCruxData from './useCruxData';

const options = {
  chartArea: { width: '90%', left: '10%', height: '80%' },
  series: [{ color: '#4D8F4F' }, { color: '#FFCB52' }, { color: '#D96157' }],
  isStacked: true,
  legend: 'none',
  hAxis: {
    minValue: 0,
    format: '#,###%',
  },
  vAxis: {
    textStyle: {
      bold: true,
    },
  },
};

const labels = {
  largest_contentful_paint: { short: 'LCP', long: 'Largest Contentful Paint' },
  first_contentful_paint: { short: 'FCP', long: 'First Contentful Paint' },
  experimental_interaction_to_next_paint: {
    short: 'ITNP',
    long: 'Interaction to Next Paint',
  },
  first_input_delay: { short: 'FID', long: 'First Input Delay' },
  experimental_time_to_first_byte: {
    short: 'TTFB',
    long: 'Time to First Byte',
  },
  cumulative_layout_shift: { short: 'CLS', long: 'Cumulative Layout Shift' },
};

const Crux = ({ selectedMoneySite }) => {
  const { data, loaded } = useCruxData({
    selectedMoneySite,
  });

  const chartData = useMemo(
    () => [
      [
        'KPI',
        '',
        { role: 'tooltip', type: 'string' },
        '',
        { role: 'tooltip', type: 'string' },
        '',
        { role: 'tooltip', type: 'string' },
      ],
      ...Object.entries(data || {}).map(([key, value]) => [
        labels?.[key]?.short,
        ...(value?.histogram?.reduce((acc, val) => {
          acc.push(val.density);
          acc.push(
            val.end ? `${val.start}ms - ${val.end}ms` : `> ${val.start}ms`,
          );
          return acc;
        }, []) || []),
      ]),
    ],
    [data],
  );

  if (loaded && !data) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <Typography variant="h2">
            <span role="img" aria-label="no data">
              🤷‍♂️
            </span>
          </Typography>
          <Typography variant="h6">No Crux Data registered</Typography>
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div style={{ position: 'relative' }}>
        <Toolbar>
          <Typography variant="h6">Chrome User Experience</Typography>
          <HelpTooltip
            source="Google Field Data"
            description={
              <span>
                The Chrome User Experience Report provides user experience
                metrics for how real-world Chrome users experience popular
                destinations on the web.
                <br />
                Each bar represents 100% of the measured users, and the
                green-orange-red areas the spread of load speed per metric.{' '}
                <br />
                *ms: milliseconds
              </span>
            }
          />
        </Toolbar>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: '40px',
            paddingRight: '40px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '220px',
              justifyContent: 'space-between',
            }}
          >
            {Object.entries(data || {}).map(([key]) => (
              <Tooltip title={labels?.[key]?.long} key={key}>
                <InfoTwoTone color="primary" />
              </Tooltip>
            ))}
          </div>
          <Chart
            chartType="BarChart"
            width="100%"
            height="300px"
            data={chartData}
            options={options}
          />
        </div>
      </div>
    </Card>
  );
};

export default memo(Crux);
