import { memo } from 'react';
import { Card, CircularProgress, Typography } from '@material-ui/core';
import { AdvancedTable } from '../../../../ui/molecules';
import HelpTooltip from '../HelpTooltip';
import Toolbar from '../Toolbar';
import { parseAmount } from '../../../../components/Helpers/utils';
import useSiteEarnings from './useSiteEarnings';

const selectedMoneySiteFields = [
  { key: 'name', label: 'Name' },
  { key: 'CPA_count', label: 'CPA Count' },
  {
    key: 'CPA_income',
    label: 'CPA Income',
    render: row => parseAmount((row && row.CPA_income) || 0),
  },
  { key: 'FTD', label: 'FTD' },
  {
    key: 'REV_income',
    label: 'REV Income',
    render: row => parseAmount((row && row.REV_income) || 0),
  },
  { key: 'Active_players', label: 'Active Players' },
  { key: 'Clicks', label: 'Clicks' },
  {
    key: 'Deposit_value',
    label: 'Deposit Value',
    render: row => parseAmount((row && row.Deposit_value) || 0),
  },
  { key: 'Deposits', label: 'Deposits' },
  { key: 'Signups', label: 'Signups' },
];

const SiteEarnings = ({ selectedMoneySite, comparison }) => {
  const { totalsData, advertisersData, loaded } = useSiteEarnings({
    selectedMoneySite,
    comparison,
  });

  if (!loaded) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      </Card>
    );
  }

  if (loaded && !advertisersData.length) {
    return (
      <Card>
        <div style={{ padding: '24px', textAlign: 'center' }}>
          <Typography variant="h2">
            <span role="img" aria-label="no data">
              🤷‍♂️
            </span>
          </Typography>
          <Typography variant="h6">No earning registered</Typography>
        </div>
      </Card>
    );
  }
  return (
    <Card>
      <div style={{ position: 'relative' }}>
        <Toolbar>
          <Typography variant="h6">Site earnings</Typography>
          <HelpTooltip
            source="Voonix APIs"
            description="Top casinos by revenue in the selected time range"
          />
        </Toolbar>
        <AdvancedTable
          feSort
          items={advertisersData.slice(0, 5)}
          footerRow={totalsData}
          fields={selectedMoneySiteFields}
        />
      </div>
    </Card>
  );
};

export default memo(SiteEarnings);
