export const trackEvent = (eventName, params) => {
  //Disable tracking
  // if (
  //   params.page_path &&
  //   typeof params.page_path === 'string' &&
  //   !skipUrl(params.page_path)
  // ) {
  //   params = processPath(params.page_path, params);
  // }
  //
  // if (
  //   params.request_url &&
  //   typeof params.request_url === 'string' &&
  //   !skipUrl(params.request_url)
  // ) {
  //   params = processPath(params.request_url, params);
  // }
  //
  // if (process.env.REACT_APP_ENV !== 'production') {
  //   // eslint-disable-next-line no-console
  //   console.log(eventName, params);
  // } else {
  //   if (typeof window.gtag === 'function') {
  //     window.gtag('event', eventName, params);
  //   }
  // }
};

export const trackAction = name => {
  trackEvent('Action', { name });
};

function processPath(url, params) {
  // Patterns to identify UUID and Numeric ID
  const uuidPattern = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
  const numericIdPattern = /^\d+$/;

  const idsFound = [];
  const urlParts = url.split('/').map(part => {
    // Check for UUID or Numeric ID
    if (uuidPattern.test(part) || numericIdPattern.test(part)) {
      idsFound.push(part);
      return 'ID'; // Replace with 'ID'
    }
    return part;
  });

  // Concatenate found IDs with a semicolon
  if (idsFound.length > 0) {
    params.uuid = idsFound.join(';');
  }

  // Reconstruct the URL with 'ID' replacing UUIDs/Numeric IDs
  url = urlParts.join('/');

  // Continue to strip unwanted parameters
  url = stripUnwantedParameters(url);

  // Update page_path or request_url
  if (params.page_path) {
    params.page_path = url;
  } else if (params.request_url) {
    params.request_url = url;
  }

  return params;
}

function skipUrl(url) {
  const urlsToSkip = ['/users/me', '/notifications/me', '/health/heartbeat'];

  // We need to check if the url CONTAINS any of the urlsToSkip
  // and return true if it does
  return urlsToSkip.some(urlToSkip => url.includes(urlToSkip));
}

function stripUnwantedParameters(url) {
  const urlObj = new URL(url, 'http://dummy-base-url.com');
  const unwantedParameters = [
    'filter',
    'order',
    'page',
    'perPage',
    'sort',
    'sortBy',
    'sortOrder',
  ];
  unwantedParameters.forEach(param => urlObj.searchParams.delete(param));
  return urlObj.pathname + urlObj.search;
}
