import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { getJson } from '../../../../components/Helpers/API';
import { useFormattedCompareDate } from '../../../../hooks/useCompareDate';

const useSiteEarnings = ({ selectedMoneySite, comparison }) => {
  const [siteEarnings, setSiteEarnings] = useState([]);
  const [advertisersData, setAdvertisersData] = useState([]);
  const [totalsData, setTotalsData] = useState();
  const [error, setError] = useState();
  const [loaded, setLoaded] = useState(false);
  const compareDate = useFormattedCompareDate(comparison, 'YYYY-MM-DD');
  const fetchSiteEarnings = useCallback(() => {
    setLoaded(false);
    setSiteEarnings([]);
    setTotalsData([]);
    getJson(
      `/dms/api/v1/websites/${
        selectedMoneySite.id
      }/scrape-site-earnings?start=${compareDate}&end=${dayjs()
        .subtract(1, 'day')
        .format('YYYY-MM-DD')}`,
    )
      .then(res => {
        if (res && res.data) {
          setSiteEarnings(res.data);
          setTotalsData({
            CPA_count: Number(res.total.CPA),
            Clicks: Number(res.total.Clicks),
            CPA_income: Number(res.total['CPA income']),
            FTD: Number(res.total.FTD),
            REV_income: Number(res.total['REV income']),
            Active_players: Number(res.total['Active players']),
            Deposit_value: Number(res.total['Deposit value']),
            Deposits: Number(res.total.Deposits),
            Signups: Number(res.total.Signups),
          });
        } else {
          console.error('Something wrong with the earnings', res);
          setError(res);
        }
      })
      .catch(err => setError(err))
      .finally(() => setLoaded(true));
  }, [selectedMoneySite, compareDate]);

  useEffect(() => {
    if (siteEarnings) {
      setAdvertisersData(() => {
        return siteEarnings
          .map(advertiser => {
            return {
              name: advertiser.Advertiser,
              CPA_count: Number(advertiser.CPA),
              Clicks: Number(advertiser.Clicks),
              CPA_income: Number(advertiser['CPA income']),
              FTD: Number(advertiser.FTD),
              REV_income: Number(advertiser['REV income']),
              Active_players: Number(advertiser['Active players']),
              Deposit_value: Number(advertiser['Deposit value']),
              Deposits: Number(advertiser.Deposits),
              Signups: Number(advertiser.Signups),
            };
          })
          .sort((a, b) => b.REV_income - a.REV_income);
      });
    }
  }, [siteEarnings, compareDate]);

  useEffect(() => {
    fetchSiteEarnings();
  }, [fetchSiteEarnings]);

  return {
    error,
    loaded,
    advertisersData,
    totalsData,
  };
};

export default useSiteEarnings;
