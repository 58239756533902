import { trackEvent } from '../../ReactGA4Helper';

async function get(url, options) {
  const TOKEN = localStorage.getItem('id_token');
  const AUTH_TYPE = localStorage.getItem('auth_type');
  const { headers, formData, ...rest } = options;

  const fullUrl = convert_url(url);
  trackEvent('API Call', {
    event_category: 'Backend Interaction',
    event_label: `GET - ${url}`,
    request_url: fullUrl,
  });

  return await fetch(fullUrl, {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      AuthType: AUTH_TYPE,
      ...(!formData ? { 'Content-Type': 'application/json' } : {}),
      ...headers,
    },
    ...rest,
  });
}

export async function getJsonPromise(url, options = {}) {
  const response = await get(url, options);
  return response.json();
}

export async function getJson(url, options = {}) {
  const response = await get(url, options);
  return await response.json();
}

export async function getText(url, options = {}) {
  const response = await get(url, options);

  if (response.ok) {
    return await response.text();
  }

  return response.statusText;
}

export async function getRaw(url, options = {}) {
  const TOKEN = localStorage.getItem('id_token');
  const AUTH_TYPE = localStorage.getItem('auth_type');

  const fullUrl = convert_url(url);

  return await fetch(fullUrl, {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
      AuthType: AUTH_TYPE,
      ...options.header,
    },
    ...options,
  });
}

function convert_url(url) {
  const API_URL = process.env.REACT_APP_API_URL;
  return `${API_URL}${url}`;
}
