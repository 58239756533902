import {
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
} from 'react-admin';
import { stringify } from 'query-string';
import { trackEvent } from './ReactGA4Helper';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sortBy: field,
        sortOrder: order,
        page: page,
        perPage: perPage,
      };
      if (Object.keys(params.filter).length > 0) {
        if (
          params.filter.statistics !== undefined &&
          Object.keys(params.filter.statistics).length > 0
        ) {
          query.statistics = JSON.stringify(params.filter.statistics);
          delete params.filter.statistics;
        }
        query.filter = JSON.stringify(params.filter);
      }
      return { url: `${API_URL}/${resource}?${stringify(query)}` };
    }
    case GET_ONE:
      return { url: `${API_URL}/${resource}/${params.id}` };
    case GET_MANY: {
      return {
        url: `${API_URL}/${resource}/get-many`,
        options: { method: 'POST', body: JSON.stringify({ ids: params.ids }) },
      };
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
        sortBy: field,
        sortOrder: order,
        page: page,
        perPage: perPage,
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
      return { url: `${API_URL}/${resource}?${stringify(query)}` };
    }
    case UPDATE:
      let method = 'PUT';

      if (resource === 'umms/api/v1/monitors') {
        method = 'PATCH';
      }

      if (params.data.ips && resource !== 'dms/api/v1/servers') {
        params.data.ips = params.data.ips.split('\n');
        params.data.ips = params.data.ips.map(ip => {
          return { ip: ip };
        });
      }

      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: { method, body: JSON.stringify(params.data) },
      };
    case CREATE:
      if (params.data.ips) {
        params.data.ips = params.data.ips.split('\n');
        params.data.ips = params.data.ips.map(ip => {
          return { ip: ip };
        });
      }

      return {
        url: `${API_URL}/${resource}`,
        options: { method: 'POST', body: JSON.stringify(params.data) },
      };
    case DELETE_MANY:
      return {
        url: `${API_URL}/${resource}?ids=${params.ids}`,
        options: { method: 'DELETE' },
      };
    case DELETE:
      return {
        url: `${API_URL}/${resource}/${params.id}`,
        options: { method: 'DELETE' },
      };
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type) => {
  const { json } = response;

  // For Servers
  if (json.data && json.data.ips) {
    json.data.ips = json.data.ips.map(ip => {
      return ip.ip;
    });
    json.data.ips = json.data.ips.join('\n');
  }

  switch (type) {
    case GET_MANY_REFERENCE:
    case GET_LIST:
      return {
        data: json.data,
        total: json.meta ? json.meta.total : null,
      };
    case GET_MANY:
      return {
        data: json.data,
      };
    case UPDATE:
    case GET_ONE:
      return {
        data: json.data,
      };
    case CREATE:
      return { data: { ...json.data } };
    case DELETE:
    case DELETE_MANY:
      return { data: json.data };
    default:
      return { data: json };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} params Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
  //Ugly overwrite for default page limit, removes options to set 10
  if (params.pagination && params.pagination.perPage === 10) {
    params.pagination.perPage = 25;
  }

  const { fetchJson } = fetchUtils;
  // eslint-disable-next-line prefer-const
  let { url, options = {} } = convertDataProviderRequestToHTTP(
    type,
    resource,
    params,
  );

  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const token = localStorage.getItem('id_token');
  const authtype = localStorage.getItem('auth_type');
  options.headers.set('Authorization', `Bearer ${token}`);
  options.headers.set('AuthType', authtype);

  trackEvent('API Call', {
    event_category: 'Backend Interaction',
    event_label: `${type} - ${resource}`,
    request_url: url,
  });

  return fetchJson(url, options)
    .then(response =>
      convertHTTPResponseToDataProvider(response, type, resource, params),
    )
    .catch(error => {
      trackEvent('API Error', {
        event_category: 'Backend Interaction',
        event_label: `${type} - ${resource}`,
        request_url: url,
        error_message: error.message || 'Unknown error',
      });
      throw error; // re-throw the error to ensure your application still handles it
    });
};
