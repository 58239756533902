import { makeStyles } from '@material-ui/core';
import { memo, useEffect, useState } from 'react';

const useStyle = makeStyles(theme => ({
  toolbar: {
    position: 'absolute',
    width: `calc(100% - ${theme.spacing(2)}px)`,
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(10),
    left: '0',
    background: '#f9f9f9',
    borderBottom: '1px solid #333',
    zIndex: 99,
    opacity: '0',
    transform: 'translateY(-80%)',
    alignItems: 'center',
    transition: '0.5s all ease',

    '&:hover': {
      opacity: 1,
      transform: 'translateY(0)',
      paddingBottom: theme.spacing(0.5),
    },
  },
  toolbarForced: {
    position: 'absolute',
    width: `calc(100% - ${theme.spacing(2)}px)`,
    padding: theme.spacing(1),
    left: '0',
    background: '#f9f9f9',
    borderBottom: '1px solid #333',
    zIndex: 99,
    alignItems: 'center',
    transition: '0.5s all ease',
    opacity: 1,
    transform: 'translateY(0)',
    paddingBottom: theme.spacing(0.5),
  },
}));

const Toolbar = ({ children }) => {
  const style = useStyle();
  const [forced, setForced] = useState(true);

  useEffect(() => {
    setForced(true);
    const t = setTimeout(() => setForced(false), 3000);
    return () => clearTimeout(t);
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      <div
        className={forced ? style.toolbarForced : style.toolbar}
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(Toolbar);
