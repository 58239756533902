import { useCallback, useEffect, useState } from 'react';
import { getJson } from '../../../../components/Helpers/API';

const useCruxData = ({ selectedMoneySite }) => {
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState();

  const fetchData = useCallback(() => {
    setLoaded(false);
    getJson(
      `/analytics/api/v1/google-analytics/crux-report/${selectedMoneySite.hostname}`,
    )
      .then(res => setData(res?.data?.record?.metrics))
      .catch(err => console.error(err))
      .finally(() => setLoaded(true));
  }, [selectedMoneySite]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { data, loaded };
};

export default useCruxData;
