import { Card, Switch, Typography } from '@material-ui/core';
import { OpenInNew } from '@material-ui/icons';
import { memo, useMemo, useState } from 'react';
import HelpTooltip from '../HelpTooltip';
import Toolbar from '../Toolbar';

const SiteEarnings = ({ selectedMoneySite }) => {
  const [desktopView, setDesktopView] = useState(false);
  const url = `https://dms.kokos.io/files/reports/${selectedMoneySite.hostname.replace(
    '.',
    '_',
  )}${desktopView ? '_desktop' : ''}.report.html`;

  const viewSwitch = useMemo(() => {
    return (
      <>
        Mobile{' '}
        <Switch
          color="primary"
          checked={desktopView}
          onChange={e => setDesktopView(e.target.checked)}
        />{' '}
        Desktop
      </>
    );
  }, [desktopView]);

  return (
    <Card variant="outlined" style={{ position: 'relative' }}>
      <Toolbar>
        <Typography variant="h6">Site Insights</Typography>
        <Typography variant="caption" style={{ marginRight: 40 }}>
          {viewSwitch}
        </Typography>
        <HelpTooltip
          source="Google Lighthouse APIs"
          description="Website performance and SEO metrics, available for mobile and desktop"
        />
      </Toolbar>
      <iframe
        title="site-insights"
        src={url}
        width="100%"
        height="300px"
        style={{ border: 'none' }}
      />
      <div
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          background: 'white',
          cursor: 'pointer',
          borderTopLeftRadius: '4px',
          padding: '8px',
        }}
      >
        <OpenInNew onClick={() => window.open(url)} />
      </div>
    </Card>
  );
};

export default memo(SiteEarnings);
